import {
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
  Link as MuiLink,
  Box,
} from '@mui/material'
import styled from 'styled-components'

import theme, { yellow50, white, gray60 } from '@theme'

import HeaderLeft from './HeaderLeft'
import UTFBLogo from './UTFBLogo'
import HeaderRight from './HeaderRight'

const { spacing } = theme

const Toolbar = styled(MuiToolbar)`
  && {
    justify-content: space-between;
    padding: 0;
    height: 56px;
    border-bottom: 2px solid ${yellow50};
  }
`

const AppBar = styled(MuiAppBar)`
  && {
    background-color: ${white};
    color: ${gray60};
    margin-bottom: ${spacing(5)};
  }
`

document.querySelector('.navigation')?.remove()

export interface HeaderProps {
  allMenusIcon: string
  canManageUsers: boolean
  hasMultipleVenues: boolean
  isPremium: boolean
  isTrialing: boolean
  launchpadIcon: string
  name: string
  subscriptionId: string
  userIcon: string
  utcIcon?: string
  utfbIcon: string
  scheduledDowngradeDate: string | null
  hasPremiumEntitlements: boolean
}

const Header = ({
  canManageUsers,
  hasMultipleVenues,
  isPremium,
  isTrialing,
  name = '',
  subscriptionId,
  userIcon,
  utcIcon = '',
  utfbIcon,
  scheduledDowngradeDate,
  hasPremiumEntitlements,
}: HeaderProps) => {
  const { pathname } = window.location
  const isLaunchpad = pathname === '/'
  const isLogin = pathname === '/login'
  const singleVenueLink = !hasMultipleVenues
  const alignLogoToLeft = (isLaunchpad || isLogin) && singleVenueLink

  return (
    <AppBar position="static" elevation={0}>
      <Toolbar>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          flex={1}
          height="100%"
          style={{ position: 'relative' }}
        >
          {!alignLogoToLeft && <HeaderLeft singleVenueLink={singleVenueLink} />}

          <Box
            pl={alignLogoToLeft ? 5 : 0}
            width={alignLogoToLeft ? 'min-content' : 250}
            whiteSpace="nowrap"
            style={
              alignLogoToLeft
                ? {}
                : {
                    position: 'absolute',
                    left: '50%',
                    marginLeft: -125,
                  }
            }
          >
            {!scheduledDowngradeDate && <UTFBLogo utfbIcon={utfbIcon} />}
          </Box>

          {!isLogin && name && (
            <Box height="100%" ml="auto">
              <HeaderRight
                canManageUsers={canManageUsers}
                isPremium={isPremium}
                isTrialing={isTrialing}
                name={name}
                subscriptionId={subscriptionId}
                userIcon={userIcon}
                scheduledDowngradeDate={scheduledDowngradeDate}
                hasPremiumEntitlements={hasPremiumEntitlements}
              />
            </Box>
          )}

          {isLogin && (
            <MuiLink href="http://www.untappd.com/" sx={{ ml: 'auto' }}>
              <Box
                display="flex"
                fontWeight="fontWeightBold"
                px={5}
                alignItems="center"
              >
                <img src={utcIcon} alt="Untappd logo" />
                <Box ml={2}>Looking for Untappd?</Box>
              </Box>
            </MuiLink>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header
