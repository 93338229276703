import { IItem, IMenu, ISection, VenueHealth } from '@app/interfaces/shared'
import qs from 'qs'

import Base from './Base'

export type LocationSearchResponse = {
  menus: IMenu[]
  sections: ISection[]
  items: IItem[]
}

class Locations extends Base {
  static async update(id: number, params: object) {
    return await this.patch(`/locations/${id}`, params)
  }

  static async health(id: number): Promise<VenueHealth> {
    const { data } = await this.get(`/locations/${id}/health`)
    return data
  }

  static async topBeers(id: number) {
    return await this.get(`/locations/${id}/top_beers`)
  }

  static async searchAll(
    id: number,
    searchQuery: string,
  ): Promise<LocationSearchResponse> {
    const { data } = await this.get(
      `/locations/${id}/search?${qs.stringify({
        q: searchQuery,
      })}`,
    )

    return data
  }
}

export default Locations
