import WebFont from 'webfontloader'
import { makeAutoObservable } from 'mobx'
import omit from 'lodash.omit'

import { OpenAIMessage } from '@app/lib/api/ThemeGenerator'
import { IBoard, IBoardFontSettings } from '@app/interfaces/boards'
import DigitalBoardStore from '.'

export default class AIAssistant {
  digitalBoardStore: DigitalBoardStore
  themeHistory: Partial<IBoard>[] = []
  messages: OpenAIMessage[] = []
  open = false

  constructor(digitalBoardStore: DigitalBoardStore) {
    this.digitalBoardStore = digitalBoardStore

    makeAutoObservable(this, { digitalBoardStore: false })
  }

  setMessages = (messages: OpenAIMessage[]) => {
    this.messages = messages
  }

  setOpen = (open: boolean) => {
    this.open = open
  }

  reset = () => {
    this.initialize()
  }

  clearMessages = () => {
    this.messages = []
  }

  initialize = () => {
    this.themeHistory = [this.board.attributes]
    this.messages = []
  }

  addIteration = (iteration: Partial<IBoard>) => {
    // When its a new iteration, we want to call the setters in order to assign the different structure side effects
    // When its an undo, we don't want to call the setters, we just want to revert to the previous state
    if (iteration.layout) this.board.setLayout(iteration.layout)
    if (iteration.orientation) this.board.setOrientation(iteration.orientation)
    if (iteration.columns) this.board.setColumns(iteration.columns)

    this.updateTheme(omit(iteration, ['layout', 'columns', 'orientation']))

    this.themeHistory.push(this.board.attributes)
  }

  undoMessage = () => {
    this.messages.pop() // Remove assistant response
    this.messages.pop() // Remove user input
  }

  undoIteration = () => {
    this.undoMessage()
    this.themeHistory.pop()

    if (this.themeHistory.length === 0) return

    const theme = this.themeHistory[this.themeHistory.length - 1]
    this.updateTheme(theme)
  }

  updateTheme = (theme: Partial<IBoard>) => {
    const setFieldIfDefined = (field: keyof IBoard, value: any) => {
      if (value === undefined) return

      this.board.setField(field, value)
    }

    Object.keys(theme).forEach((key: string) => {
      const field = key as keyof IBoard

      if (['fontSettings', 'cssOverride'].includes(field)) return
      setFieldIfDefined(field, theme[field])
    })

    if (theme.cssOverride) {
      this.board.setCssOverride(theme.cssOverride)
      this.board.customizer.cssEditor?.setValue(theme.cssOverride)
    }

    if (theme.fontSettings) {
      Object.keys(theme.fontSettings).forEach((key) => {
        const fontSettingKey = key as keyof IBoardFontSettings
        const fontStyles = this.board.fontSettings[fontSettingKey]
        // @ts-ignore
        const themeStyles: CSSObject = theme.fontSettings[key]
        if (themeStyles.fontFamily) {
          const fontFamily = themeStyles.fontFamily as string

          WebFont.load({
            google: {
              families: [fontFamily],
            },
          })
        }

        if (themeStyles.color) fontStyles.setColor(themeStyles.color)
        if (themeStyles.fontSize) fontStyles.setFontSize(themeStyles.fontSize)
        if (themeStyles.fontFamily)
          fontStyles.setFontFamily(themeStyles.fontFamily)
        if (themeStyles.fontWeight)
          fontStyles.setFontWeight(themeStyles.fontWeight)
        if (themeStyles.fontStyle)
          fontStyles.setFontStyle(themeStyles.fontStyle)
        if (themeStyles.textDecoration)
          fontStyles.setTextDecoration(themeStyles.textDecoration)
      })
    }

    this.board.save()
    this.board.fontResizer.run()
  }

  get userMessages() {
    return this.messages.filter(({ role }) => role === 'user')
  }

  get board() {
    const { board } = this.digitalBoardStore
    if (!board) throw new Error('Board not loaded')

    return board
  }
}
