export const CUSTOMIZER_WIDTH = 450
export const PREVIEW_PADDING = 48

export const OLD_EXPERIENCE_TEMPLATE_IDS = [
  'vertical_table',
  'two_column_table',
  'table_two',
  'table_three',
  'table_three_plus',
  'woodgrain',
  'vertical_stacked',
]

export const THEMES = [
  { label: 'Default', id: 'default' },
  { label: 'Chalkboard', id: 'chalk_01' },
  { label: 'Beer', id: 'beer_01' },
  { label: 'Snacktime', id: 'food_01' },
  { label: 'Pizza Box', id: 'food_02' },
  { label: 'Bricks', id: 'taps_01' },
  { label: 'Stone', id: 'taps_02' },
  { label: 'Maple', id: 'taps_03' },
  { label: 'Arcade', id: 'vibrant_01' },
  { label: 'Vineyard', id: 'wine_01' },
  { label: 'Burgundy', id: 'wine_02' },
  { label: 'Wooden', id: 'wood_01' },
  { label: 'Round Labels', id: 'round_labels' },
  { label: 'Block', id: 'block' },
  { label: 'Neon', id: 'neon' },
  { label: 'Oktoberfest', id: 'oktoberfest' },
  { label: 'Fireworks', id: 'fireworks' },
  { label: "St. Paddy's", id: 'st_paddys' },
  { label: 'Camo', id: 'camo' },
  { label: 'Football', id: 'football' },
]

export const DEPRECATED_TEMPLATES = [
  'table_three_plus',
  'table_three',
  'table_two',
  'vertical_stacked',
  'vertical_table',
  'two_column_table',
  'woodgrain',
]

export const INTERNAL_FOOTER_HEIGHT = 48

export const COLUMN_FLEX: Record<string, number> = {
  tap_number: 1,
  producer: 3,
  name: 3,
  style: 4,
  abv: 1,
  ibu: 1,
  calories: 1,
  characteristics: 4,
  vintage: 1,
  location: 4,
  rating: 2,
}

export const VERTICAL_CHECKINS_WIDTH = 300
export const INFO_BAR_HEIGHT = 94
export const INFO_BAR_PANEL_HEIGHT = 70
