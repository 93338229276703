export const white = '#ffffff'
export const black = '#000000'

export const gray10 = '#F9F9F9'
export const gray20 = '#F3F3F3'
export const gray30 = '#D8D8D8'
export const gray40 = '#898989'
export const gray50 = '#595959'
export const gray60 = '#3F403F'

export const red10 = '#F3E0DF'
export const red20 = '#F2726E'
export const red30 = '#EB5757'
export const red40 = '#D54F4F'
export const red50 = '#9B3636'

export const orange10 = '#FFE9D6'
export const orange20 = '#FFA356'
export const orange30 = '#FD9435'
export const orange40 = '#E58530'

export const yellow10 = '#FFF6DE'
export const yellow20 = '#FFDC8B'
export const yellow30 = '#FFCA45'
export const yellow40 = '#cc9900'
export const yellow50 = '#FFC000'

export const green10 = '#E6F7E5'
export const green20 = '#55C543'
export const green30 = '#20A815'
export const green40 = '#1C9113'
export const green50 = '#0E6717'

export const blue10 = '#E5F2FC'
export const blue20 = '#59A1ED'
export const blue30 = '#1E91EA'
export const blue40 = '#1B83D4'
export const blue50 = '#0259A0'
