export interface IKeen {
  projectId: string
  writeKey: string
}

export interface IConfig {
  algoliaAppId: string
  algoliaSearchKey: string
  algoliaMenuIndex: string
  fusebillPublicKey: string
  railsEnvironment: Environment
  timezones: SelectOption<string>[]
  currencies: SelectOption<string>[]
  businessCategories: SelectOption<string>[]
  defaultMaxGenericItemsCount: number
  defaultMaxUserCount: number
  statsigApiKey: string
  keen: IKeen
  rollbarAccessToken: string
  googleApiKey: string
}

export interface IMenu {
  id: number
  name: string
  unpublished: boolean
  description?: string
  updatedAt: string
  showPriceOnUntappd: boolean
  itemsCount: number
  locationId: number
}

export interface IMenuFull extends IMenu {
  sections: ISection[]
  onDeckSection: ISection | null
}

export interface ISection {
  id: number
  menuId: number
  name: string
  description: string
  itemsCount: number
  type: 'Section' | 'OnDeckSection'
  updatedAt: string
  public: boolean
  menuName?: string
  items: IItem[]
}

export interface IItem {
  id: number
  name: string
  brewery?: string | null
  producer?: string | null
  style: string | null
  category?: string | null
  vintage?: string | null
  characteristics?: string | null
  location?: string | null
  breweryLocation?: string | null
  abv?: number | string | null
  ibu?: number | string | null
  calories?: string | null
  labelImage: string
  labelImageThumb: string
  type: string
  description?: string
  rating?: number
  tapNumber?: string
  inProduction: boolean
  untappdId?: number
  untappdBreweryId?: number
  sourceIdentifier?: string
  itemSourceId?: number
  nitro: boolean
  cask: boolean
  local: boolean
  customLabelImage?: string | null
  customLabelImageThumb?: string | null
  customLabelImageFilename?: string | null
  updatedAt: string
  sectionId: number
  menuId?: number
  menuName?: string
  containers: IContainer[]
  createdAt: string
  position: number
}

export interface IContainer {
  itemId?: number | null
  id?: number | string | null
  containerSizeId?: number | string
  name?: string | null
  price?: string | number | null
  position?: number | null
  calories?: string | number | null
  currency?: string | null
  currencySymbol?: string | null
  key?: string | null
}

export interface Position {
  id: number | string
  position: number
}

export interface Link {
  title: string
  href: string
}

export enum UserRole {
  DEN_MASTER = 'DEN_MASTER',
  GREEN_TURTLE_USER = 'GREEN_TURTLE_USER',
  OWNER = 'OWNER',
  USER = 'USER',
}

export interface IUser {
  id: number
  dismissedNotices: Set<string>
  acceptedTos: boolean
  admin: boolean
  confirmedAt?: string
  creationMethod: CreationMethod
  email: string
  firstName: string
  firstSignInAt?: Date
  lastSeenAt?: string
  impersonatingUser: boolean
  lastName?: string
  name: string
  role: UserRole
  activeVenueCount: number
  managesSubscriptions: boolean
}

export interface IMember {
  id: number
  userId: number
  enabled: boolean
  membersCanManageMembers: boolean
  venuesCanAddAndRemoveBeers: boolean
  venuesCanAddAndRemoveSections: boolean
  venuesCanCreateEvent: boolean
  venuesCanCreateMenu: boolean
  venuesCanDeleteMenu: boolean
  venuesCanEditBeerInfo: boolean
  venuesCanListBusinessAnalytics: boolean
  venuesCanListDigitalBoards: boolean
  venuesCanListHours: boolean
  venuesCanListInvoices: boolean
  venuesCanListMenuAnnouncements: boolean
  venuesCanListNearbyAnalytics: boolean
  venuesCanListPrintThemes: boolean
  venuesCanListVenueAnalytics: boolean
  venuesCanListWebsiteThemes: boolean
  venuesCanShowLocationDetails: boolean
  venuesCanShowLocationInformation: boolean
  venuesCanUpdateMenuName: boolean
  venuesCanUpdateSectionName: boolean
}

export interface VenueHealth {
  [key: string]: boolean
  venueVerified: boolean
  logoUploaded: boolean
  menuCreated: boolean
  webEmbedMenu: boolean
  digitalBoard: boolean
  printMenu: boolean
}

export interface IVenuePromo {
  id: number
  startDate: string
  userAcknowledgedScheduledAt: string
}

export interface Venue {
  id: number
  address1: string
  address2: string
  region: string
  city: string
  postcode: string
  country: string
  mailingAddress1: string
  mailingAddress2: string
  mailingCity: string
  mailingRegion: string
  mailingPostcode: string
  mailingCountry: string
  countryName: string
  genericItemsCount: number
  health: VenueHealth | null
  menus: IMenu[]
  name: string
  untappdVenueId: number
  verified: boolean
  businessType: string | null
  promos: IVenuePromo[]
  canCreateUntappdBeer: boolean
  timezone: string
  timezoneOffset: number
  ianaTimezone: string
  currencySymbol: string
  festivalEndOn: string | null
  festivalStartOn: string | null
}

export interface LocalBadge {
  id: number
  name: string
}

export interface SupportTool {
  id: number
  name: string
}

export interface SalesTool {
  id: number
  name: string
}

export type Productable = Venue | LocalBadge | SupportTool | SalesTool

export type SubscriptionStatus =
  | 'active'
  | 'trialing'
  | 'suspended'
  | 'pending_payment'

export type ProductableType =
  | 'Location'
  | 'LocalBadge'
  | 'SupportTool'
  | 'SalesTool'

export enum IntegrationType {
  OZNR = 'OznrIntegration',
  SQUARE = 'SquareIntegration',
}

export interface IIntegration {
  id: number
  type: IntegrationType
  accessToken: string
  refreshToken: string
  expiresAt: string
  updatedAt: string
  createdAt: string
}

export interface ISubscription {
  id: number
  complimentaryPremiumEndAt?: string
  complimentaryPremiumActive: boolean
  complimentaryPremiumExpired: boolean
  createdAt: string
  entitlements: IEntitlements
  fusebillCustomerId?: number
  fusebillSubscriptionId?: number
  maxGenericItemsExceeded: boolean
  maxUsersExceeded: boolean
  members: IMember[]
  salesforceAccountId: string
  scheduledMigrationDate: string | null
  source: CreationMethod
  status: SubscriptionStatus
  tier: Tier
  trialStartAt: string | null
  trialEndAt: string | null
  trialConvertedAt: string | null
  userId: number
  users: IUser[]
  productable: Productable
  demo: boolean
  enterprise: boolean
  productableType: ProductableType
  planInterval: Term
  integrations: IIntegration[]
}

export interface IEntitlements {
  apiAccess: boolean
  maxGenericItems: number
  maxUsers: number
}

export interface VenueRouteParams {
  subscriptionId: string | Record<string, string | undefined>
}

export enum CreationMethod {
  SALES_ASSISTED = 'sales_assisted',
  SELF_SERVICE = 'self_service',
}

export interface CreateUserInput {
  firstName: string
  lastName?: string
  email: string
  password: string
  creationMethod?: CreationMethod
}

export interface UntappdVenue {
  untappdVenueId: number
  name: string
  address1: string
  city: string
  region: string
  country: string
  imageUrl: string
  latitude?: number
  longitude?: number
  objectID: number
}

export interface SelectOption<Value = string> {
  value: Value
  label: string
  disabled?: boolean
  group?: string
}

export interface UpgradeValues {
  firstName: string
  lastName: string
  address1: string
  address2: string
  city: string
  region: string
  postcode: string
  country: string
  cardNumber: string
  expiration: string
  cvc: string
  recaptchaToken: string
  acceptedSaasAgreement: boolean
}

export enum PlanCode {
  ESSENTIALS_MONTHLY = 'utfb-monthly-msrp',
  ESSENTIALS_YEARLY = 'utfb-annual-msrp',
  PREMIUM_MONTHLY = 'utfbpremium-monthly-msrp',
  PREMIUM_YEARLY = 'utfbpremium-annual-msrp',
}

export enum Term {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum Tier {
  ESSENTIALS = 'essentials',
  PREMIUM = 'premium',
}

export enum Environment {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  TEST = 'test',
}

export enum FusebillPlanRelationshipMigrationType {
  UPGRADE = 'Upgrade',
  DOWNGRADE = 'Downgrade',
  PLAN_UPDATE = 'PlanUpdate',
  FREQUENCY_CHANGE = 'FrequencyChange',
  FREE_TRIAL_CONVERSION = 'FreeTrialConversion',
  OTHER = 'Other',
}

export interface CreditCard {
  id: number
  maskedCardNumber: string
  cardType: string
  expirationMonth: number
  expirationYear: number
  firstName: string
  lastName: string
  address1: string
  address2: string
  countryId: number
  stateId: number
  city: string
  postalZip: string
}

export interface InvoicePreview {
  nextRechargeDate: string
  subtotal: number
  tax: number
  total: number
  prorated: boolean
  unitPrice: number
  credit?: number
}

export interface FusebillProductFrequency {
  planFrequencyId: number
  interval: Term
  amount: number
}

export interface FusebillProduct {
  code: string
  planProductUniqueId: number
  frequencies: FusebillProductFrequency[]
}

export interface IFusebillPlan {
  code: string
  products: FusebillProduct[]
}

export interface IFusebillPlanRelationship {
  id: number
  relationshipMigrationType: FusebillPlanRelationshipMigrationType
  sourcePlanCode: string
  destinationPlanCode: string
}

export interface IFile {
  filename: string
  url: string
}

export interface IFavoriteContainer {
  id?: number | string | null
  name?: string
  price?: string | number | null
  updatedAt?: string
  default?: boolean
  itemType?: ModulizedItemType
  containerSizeId?: string | number
  calories?: string | number | null
  position?: number | null
}

export type ModulizedItemType =
  | 'Items::Beer'
  | 'Items::Wine'
  | 'Items::Spirit'
  | 'Items::ReadyToDrink'
  | 'Items::Cocktail'
  | 'Items::NonAlcoholic'
  | 'Items::Food'
  | 'Items::Flight'
  | 'Items::Merchandise'
  | 'Items::Miscellaneous'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ChurnZero: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    drift: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pendo: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clarity: any

    ReactNativeWebView: {
      postMessage: (message: string) => void
    }
  }
}
