import { makeAutoObservable } from 'mobx'
import moment from 'moment'
import algoliaAnalytics from 'search-insights'

import { IUser, CreationMethod, UserRole } from '@app/interfaces/shared'
import CurrentUserApi from '@app/lib/api/CurrentUser'
import RootStore from '../RootStore'

export default class CurrentUser {
  rootStore: RootStore

  id: number

  dismissedNotices: Set<string>

  acceptedTos: boolean

  admin: boolean

  confirmedAt?: string

  creationMethod: CreationMethod

  lastSeenAt?: string

  email: string

  firstSignInAt?: Date

  impersonatingUser: boolean

  lastName?: string

  name: string

  role: UserRole

  activeVenueCount: number

  managesSubscriptions: boolean

  constructor(rootStore: RootStore, user: IUser) {
    this.rootStore = rootStore
    this.id = user.id
    this.dismissedNotices = new Set(user.dismissedNotices)
    this.acceptedTos = user.acceptedTos
    this.admin = user.admin
    this.confirmedAt = user.confirmedAt
    this.creationMethod = user.creationMethod
    this.lastSeenAt = user.lastSeenAt
    this.email = user.email
    this.firstSignInAt = user.firstSignInAt
    this.impersonatingUser = user.impersonatingUser
    this.lastName = user.lastName
    this.name = user.name
    this.role = user.role
    this.activeVenueCount = user.activeVenueCount
    this.managesSubscriptions = user.managesSubscriptions

    makeAutoObservable(this, { rootStore: false })

    this.initializePendo()
    // Put user in local storage for Zendesk script
    localStorage.setItem('viewer', JSON.stringify(user))
    if (localStorage.getItem('impersonating') && this.impersonatingUser) {
      this.rootStore.uiStore.toaster.info(
        `You are now impersonating ${user.name}`,
      )
      localStorage.removeItem('impersonating')
    }
  }

  set = (params: Partial<IUser>) => {
    Object.assign(this, params)
  }

  dismissNotice = async (notice: string) => {
    this.dismissedNotices.add(notice)
    return CurrentUserApi.dismissNotice(notice)
  }

  get daysSinceFirstSignIn(): number {
    return moment().diff(moment(this.firstSignInAt), 'd')
  }

  get isDenMaster(): boolean {
    return this.role === UserRole.DEN_MASTER
  }

  get isGreenTurtle(): boolean {
    return this.role === UserRole.GREEN_TURTLE_USER
  }

  initializePendo(): void {
    // Update Pendo visitor tracking
    if (this.impersonatingUser || !window.pendo) return

    const { id, email, name, creationMethod, lastSeenAt } = this

    algoliaAnalytics('setUserToken', String(id))
    pendo.initialize({
      visitor: {
        id: String(id),
        email,
        name,
        creationMethod: creationMethod.toLowerCase(),
        lastActivity: lastSeenAt || '',
      },
    })
  }

  get isInternal(): boolean {
    if (this.impersonatingUser) return true

    return this.admin
  }
}
