import { makeAutoObservable } from 'mobx'
import isEmpty from 'lodash.isempty'

import RootStore from '@app/stores/RootStore'
import {
  CreditCard,
  Term,
  Tier,
  IFusebillPlanRelationship,
  InvoicePreview,
} from '@app/interfaces/shared'
import FusebillPlan from '@app/stores/models/FusebillPlan'
import Subscriptions from '@app/lib/api/Subscriptions'
import Subscription from '@app/stores/models/Subscription'

class CheckoutStore {
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore

    makeAutoObservable(this, { rootStore: false })
  }

  rootStore: RootStore

  creditCard: CreditCard | null = null

  term: Term = Term.MONTHLY

  tier: Tier = Tier.ESSENTIALS

  currentPlan: FusebillPlan | null = null

  plans: FusebillPlan[] = []

  planRelationships: IFusebillPlanRelationship[] = []

  plan: FusebillPlan | null = null

  invoicePreview: InvoicePreview | null = null

  setInvoicePreview = (invoicePreview: InvoicePreview) => {
    this.invoicePreview = invoicePreview
  }

  loadInvoicePreview = async () => {
    if (!this.plan || !this.subscription.fusebillCustomerId) return

    if (this.isPlanUpdate) {
      const { data } = await Subscriptions.migrationInvoicePreview({
        subscriptionId: this.subscription.id,
        planRelationshipId: this.migrationRelationship!.id,
        migrationTiming: 'Now',
      })

      if (data.error) throw new Error(data.error.detail)

      this.setInvoicePreview(data)
      return
    }

    const { data } = await Subscriptions.invoicePreview({
      subscriptionId: this.subscription.id,
      planProductUniqueId: this.plan.product.planProductUniqueId,
      planFrequencyId: this.plan.frequency.planFrequencyId,
    })

    if (data.error) throw new Error(data.error.detail)

    this.setInvoicePreview(data)
  }

  loadCurrentPlan = async (): Promise<void> => {
    const { data } = await Subscriptions.currentPlan(this.subscription.id)

    this.setCurrentPlan(data ? new FusebillPlan(data) : null)
  }

  loadPlanFamily = async (): Promise<void> => {
    const { data } = await Subscriptions.planFamily(this.subscription.id)

    this.setPlans(data.plans)
    this.setPlanRelationships(data.planRelationships)
  }

  loadDefaultCreditCard = async (): Promise<void> => {
    try {
      const { data } = await Subscriptions.defaultCreditCard(
        this.subscription.id,
      )
      this.setCreditCard(data)
    } catch {
      // noop
    }
  }

  loadCustomer = async (): Promise<number> => {
    if (this.subscription.fusebillCustomerId) {
      return this.subscription.fusebillCustomerId
    }

    const { data } = await Subscriptions.findOrCreateCustomer(
      this.subscription.id,
    )

    this.subscription.set({ fusebillCustomerId: data.fusebillCustomerId })

    return data.fusebillCustomerId
  }

  upgrade = async () => {
    let response
    if (this.isPlanUpdate) {
      response = await Subscriptions.upgrade({
        subscriptionId: this.subscription.id,
        planRelationshipId: this.migrationRelationship!.id,
        migrationTiming: 'Now',
      })
    } else {
      response = await Subscriptions.upgradeFromTrial({
        subscriptionId: this.subscription.id,
        planProductUniqueId: this.plan!.product.planProductUniqueId,
        planFrequencyId: this.plan!.frequency.planFrequencyId,
      })
    }

    const { data } = response

    if (data.error) throw new Error(data.error.detail)

    this.subscription.set({
      status: data.subscription.status,
      tier: data.subscription.tier,
      fusebillCustomerId: data.subscription.fusebillCustomerId,
      fusebillSubscriptionId: data.subscription.fusebillSubscriptionId,
    })
    this.setCurrentPlan(new FusebillPlan(data.plan))
  }

  setCurrentPlan = (plan: FusebillPlan | null): void => {
    this.currentPlan = plan
  }

  setPlans = (plans: FusebillPlan[]): void => {
    this.plans = plans.map((plan) => new FusebillPlan(plan))
  }

  setPlanRelationships = (
    planRelationships: IFusebillPlanRelationship[],
  ): void => {
    this.planRelationships = planRelationships
  }

  setTier = (tier: Tier): void => {
    this.tier = tier
  }

  setTerm = (term: Term): void => {
    this.term = term
  }

  setPlan = (plan: FusebillPlan): void => {
    this.plan = plan
  }

  setCreditCard = (creditCard: CreditCard): void => {
    this.creditCard = creditCard
  }

  get subscription(): Subscription {
    const { currentSubscription } = this.rootStore
    if (!currentSubscription) throw new Error('No subscription in store')

    return currentSubscription
  }

  get initialized(): boolean {
    return !isEmpty(this.plans)
  }

  get isPlanUpdate(): boolean {
    return Boolean(this.currentPlan)
  }

  get migrationRelationship(): IFusebillPlanRelationship | undefined {
    return this.planRelationships.find(
      (planRelationship) =>
        planRelationship.sourcePlanCode === this.currentPlan?.code &&
        planRelationship.destinationPlanCode === this.plan?.code,
    )
  }
}

export default CheckoutStore
