import { makeAutoObservable } from 'mobx'
import WebFont from 'webfontloader'

import DigitalBoards from '@app/lib/api/DigitalBoards'
import Board from './Board'
import FontStyles from './FontSettings/FontStyles'

export default class Fonts {
  board: Board
  loaded = false
  uploading = false

  constructor(board: Board) {
    this.board = board
    this.load()

    makeAutoObservable(this, { board: false })
  }

  load = () => {
    const defaultFontFamilies = Object.values(this.board.fontFamilies).flatMap(
      (families) => families,
    )

    WebFont.load({
      google: {
        families: [...defaultFontFamilies, ...this.usedFontFamilies],
      },
      active: async () => {
        this.board.customFontFamilies.forEach(async (font) => {
          const fontFace = new FontFace(font.name, `url(${font.url})`)
          document.fonts.add(fontFace)
          await fontFace.load()
        })

        await document.fonts.ready
        this.setLoaded(true)
      },
    })
  }

  upload = async (file: File | null) => {
    if (!file) return
    this.setUploading(true)
    const {
      data: {
        digitalBoard: { customFontFamilies },
      },
    } = await DigitalBoards.uploadFont(this.board.id, file)
    this.board.set({ customFontFamilies })

    if (customFontFamilies.length) {
      const font = customFontFamilies[customFontFamilies.length - 1]
      const fontFace = new FontFace(font.name, `url(${font.url})`)
      document.fonts.add(fontFace)
      await fontFace.load()
      await document.fonts.ready
    }
    this.setUploading(false)
  }

  setLoaded = (loaded: boolean) => {
    this.loaded = loaded
  }

  setUploading = (uploading: boolean) => {
    this.uploading = uploading
  }

  get usedFontFamilies() {
    const usedFontFamilies = Object.values(this.board.fontSettings).map(
      (fontSetting: FontStyles) => fontSetting.fontFamily,
    )

    return Array.from(new Set(usedFontFamilies))
  }

  get fontFamilyOptions() {
    const fontFamilies = Array.from(
      new Set([...this.board.fontFamilies, ...this.usedFontFamilies]),
    )

    fontFamilies.sort()

    const fontFamilyOptions = fontFamilies.map((font) => ({
      label: font,
      value: font,
    }))

    if (this.board.customFontFamilies.length) {
      fontFamilyOptions.push(
        ...this.board.customFontFamilies.map((font) => ({
          label: font.name,
          value: font.name,
          group: 'Custom Fonts',
        })),
      )
    }

    return fontFamilyOptions
  }
}
