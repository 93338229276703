import { makeAutoObservable } from 'mobx'
import isEmpty from 'lodash.isempty'
import { Monaco } from '@monaco-editor/react'
import humps from 'humps'

import { IContainer, IItem } from '@app/interfaces/shared'

import Board from '../DigitalBoardStore/Board'

export default class CustomizerStore {
  customizable: Board
  tab = 'theme'
  showResetThemeStylesModal = false
  cssEditor: Monaco | null = null

  openSections = new Set<string>([])

  constructor(customizable: Board) {
    this.customizable = customizable

    makeAutoObservable(this, { customizable: false })
  }

  setTab = (tab: string) => {
    this.tab = tab
  }

  toggleSection = (option: string) => {
    if (this.openSections.has(option)) {
      this.openSections.delete(option)
      return
    }
    this.openSections.add(option)
  }

  toggleResetThemeStylesModal = () => {
    this.showResetThemeStylesModal = !this.showResetThemeStylesModal
  }

  displayField(field: string, item: IItem): boolean {
    const displayMethod = `display${humps.pascalize(field)}`
    // @ts-ignore
    if (!this[displayMethod]) return false
    // @ts-ignore
    return this[displayMethod](item)
  }

  displayTapNumber(item: IItem) {
    return this.customizable.showTapNumber && Boolean(item.tapNumber)
  }

  displayProducer(item: IItem) {
    if (item.brewery && this.customizable.showBreweryName) return true
    if (!item.producer) return false
    if (item.type === 'wine') return this.customizable.showWineProducer
    if (item.type === 'spirit') return this.customizable.showSpiritProducer
    if (item.type === 'ready_to_drink') return this.customizable.showRtdProducer

    return false
  }

  displayName(item: IItem) {
    return Boolean(item.name)
  }

  displayAbv(item: IItem) {
    if (!item.abv || item.abv === 0.0) return false
    if (item.type === 'beer') return this.customizable.showAbv

    if (!this.customizable.isPremium) return false
    if (item.type === 'wine') return this.customizable.showWineAbv
    if (item.type === 'spirit') return this.customizable.showSpiritAbv
    if (item.type === 'ready_to_drink') return this.customizable.showRtdAbv

    return false
  }

  displayIbu(item: IItem) {
    return this.customizable.showIbu && item.ibu && Number(item.ibu) > 0
  }

  displayCalories(item: IItem) {
    if (!item.calories) return false
    if (item.type === 'beer') return this.customizable.showCalories
    if (item.type === 'wine') return this.customizable.showWineCalories
    if (item.type === 'spirit') return this.customizable.showSpiritCalories
    if (item.type === 'ready_to_drink') return this.customizable.showRtdCalories

    return false
  }

  displayRating(item: IItem) {
    return this.customizable.showUntappdRating && Boolean(item.rating)
  }

  displayVintage(item: IItem) {
    if (!item.vintage) return false
    if (!this.customizable.isPremium) return false

    if (item.type === 'wine') return this.customizable.showWineVintage
    if (item.type === 'spirit') return this.customizable.showSpiritVintage
    if (item.type === 'ready_to_drink') return this.customizable.showRtdVintage

    return false
  }

  displayStyle(item: IItem) {
    if (item.type === 'beer') {
      return this.customizable.showBeerStyle && Boolean(item.style)
    }

    if (!item.category) return false
    if (!this.customizable.isPremium) return false

    if (item.type === 'wine') return this.customizable.showWineCategory
    if (item.type === 'spirit') return this.customizable.showSpiritCategory
    if (item.type === 'ready_to_drink') return this.customizable.showRtdCategory

    return false
  }

  displayCharacteristics(item: IItem) {
    if (!this.customizable.isPremium) return false

    return (
      this.customizable.showWineCharacteristics && Boolean(item.characteristics)
    )
  }

  displayLocation(item: IItem) {
    if (item.type === 'wine') {
      return (
        this.customizable.showWineRegion &&
        item.location &&
        this.customizable.isPremium
      )
    }

    return (
      this.customizable.showBreweryLocation && Boolean(item.breweryLocation)
    )
  }

  displayDescription(item: IItem) {
    if (!item.description) return false
    if (item.type === 'beer') return this.customizable.showBeerDescription
    if (item.type === 'wine') return this.customizable.showWineDescription
    if (item.type === 'spirit') return this.customizable.showSpiritDescription
    if (item.type === 'ready_to_drink')
      return this.customizable.showRtdDescription
    if (item.type === 'food') return this.customizable.showFoodDescription
    return this.customizable.showGenericItemDescription
  }

  displayLabelImage(item: IItem) {
    if (item.type === 'beer') return this.customizable.showBeerLabelImage
    if (item.type === 'wine') return this.customizable.showWineLabelImage
    if (item.type === 'spirit') return this.customizable.showSpiritLabelImage
    if (item.type === 'ready_to_drink')
      return this.customizable.showRtdLabelImage
    if (item.type === 'food') return this.customizable.showFoodLabelImage
    return this.customizable.showGenericItemLabelImage
  }

  displayContainers(item: IItem) {
    if (isEmpty(item.containers)) return false
    return this.customizable.showContainers
  }

  displayContainerName(container: IContainer) {
    if (!container.name) return false
    return this.customizable.showContainerName
  }

  displayContainerCalories(container: IContainer) {
    if (!container.calories) return false
    return this.customizable.showContainerCalories
  }

  displayContainerPrice(container: IContainer) {
    if (!container.price) return false
    return this.customizable.showPrice
  }

  resetCssEditor = () => {
    if (!this.cssEditor) return
    this.cssEditor.setValue(this.customizable.cssOverride)
  }

  setField = <K extends keyof typeof this>(
    field: K,
    value: (typeof this)[K],
  ) => {
    this[field] = value
  }

  get showTapNumber() {
    if (!this.hasField('tap_number')) return false

    return this.customizable.showTapNumber
  }

  get showProducer() {
    if (!this.hasField('producer')) return false

    return (
      this.customizable.showBreweryName ||
      this.customizable.showSpiritProducer ||
      this.customizable.showRtdProducer ||
      this.customizable.showWineProducer
    )
  }

  get showName() {
    return true
  }

  get showStyle() {
    if (!this.hasField('style')) return false

    return (
      this.customizable.showBeerStyle ||
      this.customizable.showWineCategory ||
      this.customizable.showSpiritCategory ||
      this.customizable.showRtdCategory
    )
  }

  get showCharacteristics() {
    return (
      this.customizable.showWineCharacteristics &&
      this.hasField('characteristics')
    )
  }

  get showLocation() {
    if (!this.hasField('location')) return false

    return (
      this.customizable.showWineRegion || this.customizable.showBreweryLocation
    )
  }

  get showDescription() {
    return (
      this.customizable.showWineDescription ||
      this.customizable.showSpiritDescription ||
      this.customizable.showRtdDescription ||
      this.customizable.showGenericItemDescription ||
      this.customizable.showFoodDescription ||
      this.customizable.showBeerDescription
    )
  }

  get showCalories() {
    if (!this.hasField('calories')) return false

    return (
      this.customizable.showWineCalories ||
      this.customizable.showSpiritCalories ||
      this.customizable.showRtdCalories ||
      this.customizable.showCalories
    )
  }

  get showRating() {
    return this.customizable.showUntappdRating && this.hasField('rating')
  }

  get showAbv() {
    if (!this.hasField('abv')) return false

    return (
      (this.customizable.isPremium &&
        (this.customizable.showWineAbv ||
          this.customizable.showSpiritAbv ||
          this.customizable.showRtdAbv)) ||
      this.customizable.showAbv
    )
  }

  get showIbu() {
    return this.customizable.showIbu && this.hasField('ibu')
  }

  get showVintage() {
    if (!this.hasField('vintage')) return false
    if (!this.customizable.isPremium) return false

    return (
      this.customizable.showWineVintage ||
      this.customizable.showSpiritVintage ||
      this.customizable.showRtdVintage
    )
  }

  get showContainers() {
    return this.customizable.showContainers
  }

  hasField(field: string) {
    return this.customizable.visibleItems.some((item) =>
      this.displayField(field, item),
    )
  }
}
