import { makeAutoObservable, runInAction } from 'mobx'
import debounce from 'lodash.debounce'
import humps from 'humps'
import omit from 'lodash.omit'
import KeenTracking from 'keen-tracking'
import isEqual from 'lodash.isequal'

import Ads from './Ads'
import { IMenuFull } from '@app/interfaces/shared'
import {
  IBoard,
  IBoardOptions,
  IBoardSettings,
  IEvent,
  ICheckin,
  IBoardThemeOptions,
  CustomFontFamily,
  FontSizing,
  IBoardFontSettings,
  IAdSlide,
} from '@app/interfaces/boards'
import DigitalBoards from '@app/lib/api/DigitalBoards'
import {
  CUSTOMIZER_WIDTH,
  PREVIEW_PADDING,
  OLD_EXPERIENCE_TEMPLATE_IDS,
  DEPRECATED_TEMPLATES,
} from '@app/pages/DigitalBoards/constants'
import Customizer from '@app/stores/CustomizerStore'
import DigitalBoardStore from '.'
import InfoBar from './InfoBar'
import FontResizer from './FontResizer'
import FontSettings from './FontSettings'
import Menu from './Menu'
import Section from './Section'
import Fonts from './Fonts'
import Channels from './Channels'
import FontStyles from './FontSettings/FontStyles'

const DEFAULT_HEIGHT = 1080
const DEFAULT_WIDTH = 1920

export default class Board implements IBoard {
  digitalBoardStore: DigitalBoardStore
  subscriptionTier: string
  announcement: string
  hasNewExperience: boolean
  id: number
  settings: IBoardSettings
  columns: number
  locationId: number
  createdAt: string
  updatedAt: string
  backgroundImage?: File | null
  backgroundColor: string
  backgroundImageFileName: string | null
  backgroundImageUrl: string | null
  backgroundImageOpacity: number
  headerImage?: File | null
  headerImageFileName: string | null
  headerImageUrl: string | null
  headerImageSize: number
  headerImageAlignment: string
  beersOnScreen: number
  cssOverride: string
  primaryFontColor: string
  secondaryFontColor: string
  untappdRatingColor: string
  fontFamily: string
  name: string
  multipleDisplays: boolean
  orientation: 'landscape' | 'portrait'
  screenRotationSeconds: number
  showAbv: boolean
  showAdSlides: boolean
  showAnnouncements: boolean
  showBeerStyle: boolean
  showBreweryLocation: boolean
  showBreweryName: boolean
  showCheckins: boolean
  showCurrencySymbol: boolean
  showEvents: boolean
  showIbu: boolean
  showLabelImage: boolean
  showBeerLabelImage: boolean
  showWineLabelImage: boolean
  showSpiritLabelImage: boolean
  showRtdLabelImage: boolean
  showFoodLabelImage: boolean
  showGenericItemLabelImage: boolean
  showMenuTitles: boolean
  showPrice: boolean
  showSectionTitles: boolean
  showTapNumber: boolean
  showUntappdRating: boolean
  template: string
  showNewStatus: boolean
  menuTitleBackgroundColor: string
  menuTitleFontColor: string
  sectionTitleBackgroundColor: string
  sectionTitleFontColor: string
  theme: string
  themeOptions: IBoardThemeOptions
  adSlideDisplayStyle: string
  website: string
  labelImageCornerRadius: string
  showCalories: boolean
  showOnDeckItems: boolean
  pageBreakAfterMenu: boolean
  showContainerName: boolean
  showContainerCalories: boolean
  showGenericItemDescription: boolean
  showSpiritProducer: boolean
  showSpiritCategory: boolean
  showSpiritAbv: boolean
  showSpiritVintage: boolean
  showSpiritCalories: boolean
  showWineProducer: boolean
  showWineCategory: boolean
  showWineAbv: boolean
  showWineCharacteristics: boolean
  showWineVintage: boolean
  showWineCalories: boolean
  showWineRegion: boolean
  showBeerDescription: boolean
  showFoodDescription: boolean
  showSpiritDescription: boolean
  showWineDescription: boolean
  showRtdCalories: boolean
  showRtdCategory: boolean
  showRtdDescription: boolean
  showRtdProducer: boolean
  showRtdVintage: boolean
  showRtdAbv: boolean
  fontSizing: FontSizing
  primaryAccentColor: string
  onDeckTagBackgroundColor: string
  newTagBackgroundColor: string
  itemDetailOrder: string[]
  layout: IBoard['layout']
  containerLayout: IBoard['containerLayout']
  defaults: IBoard['defaults']
  showCommonBreweryTerms: boolean
  checkinsPlacement: IBoard['checkinsPlacement']

  channels: Channels
  customizer: Customizer
  infoBar: InfoBar
  fontResizer: FontResizer
  fontSettings: FontSettings
  fonts: Fonts
  fontFamilies: string[]
  customFontFamilies: CustomFontFamily[]
  menus: Menu[] = []
  adSlides: IAdSlide[] = []
  ads: Ads = new Ads(this, [])
  events: IEvent[] = []
  checkins: ICheckin[] = []
  isLoading = false
  public = false
  scale = 1
  footerHeight = 64
  page = 0
  decisecondsElapsed = 0
  paginationInterval: NodeJS.Timeout | null = null
  primaryFields: string[] = []
  secondaryFields: string[] = []
  selectedTheme: string | null = null

  constructor(
    digitalBoardStore: DigitalBoardStore,
    board: IBoard,
    options: IBoardOptions = {
      public: false,
      page: 0,
    },
  ) {
    this.digitalBoardStore = digitalBoardStore
    this.public = options.public
    this.page = options.page
    this.set(board)

    if (!board.showLabelImage) this.showAllLabelImages = false

    // Required to support old boards that were created before the new experience was released
    // This sets all container information to false, but only if the legacy `showContainers` field is false
    if (!board.showContainers) this.showContainers = false

    this.customizer = new Customizer(this)
    this.infoBar = new InfoBar(this)
    this.fontResizer = new FontResizer(this)
    this.fonts = new Fonts(this)
    this.channels = new Channels(this)

    this.setupKeyboardNavigation()

    if (this.hasItems) {
      this.startPagination()
    } else if (this.ads.adSlidesWithContent.length > 0 && this.showAdSlides) {
      this.rotateAdSlides(0)
    }

    if (this.public) {
      this.trackPageView()
      this.setInitialViewportScale()
      this.channels.subscribe()
      this.listenForFireTVRotation()
      this.trackDevice()
      this.startReloadTimeout()
    }

    makeAutoObservable(this, { digitalBoardStore: false })
  }

  save = async () => {
    return DigitalBoards.update(this.id, this.attributes)
  }
  debounceSave = debounce(this.save, 500)

  setPage = (page: number) => {
    this.page = page
  }

  setMenus = (menus: Menu[]) => {
    this.menus = menus
  }

  setAds = (ads: Ads) => {
    this.ads = ads
  }

  setEvents = (events: IEvent[]) => {
    this.events = events
  }

  setCheckins = (checkins: ICheckin[]) => {
    this.checkins = checkins
  }

  setSelectedTheme = (theme: string | null) => {
    this.selectedTheme = theme
  }

  refresh = async () => {
    const { data } = await DigitalBoards.retrieve(this.id)
    this.set(data.digitalBoard)
    this.fonts.load()
    this.resize()
  }

  loadMenus = async () => {
    const { data } = await DigitalBoards.menus(this.id)
    this.setMenus(data.menus.map((menu: IMenuFull) => new Menu(this, menu)))

    const page = this.pageCount > 0 ? this.page % this.pageCount : 0
    this.setPage(page)

    if (this.hasItems) this.startPagination()
  }

  loadCheckins = async () => {
    const { data } = await DigitalBoards.checkins(this.id)
    this.setCheckins(data.checkins)
    this.fontResizer.run()
  }

  set = (board: Partial<IBoard>) => {
    Object.assign(this, omit(board, 'fontSettings', 'width', 'height'))

    const adsVisible = this.ads?.visible
    if (this.ads?.rotationIntervalId) clearInterval(this.ads.rotationIntervalId)
    this.ads = new Ads(this, board.adSlides || [])
    if (adsVisible) this.ads.startSlideRotation()

    if (board.fontSettings) {
      this.fontSettings = new FontSettings(this, board.fontSettings)
    }
    if (board.menus) {
      this.menus = board.menus.map((menu) => new Menu(this, menu))
    }
  }

  updateTheme = async (theme: string) => {
    if (theme === this.theme) return
    DigitalBoards.update(this.id, { theme })
    const { fontSettings, ...themeSettings } = this.themeOptions[theme]
    this.set({ theme, ...themeSettings })

    if (!fontSettings) return

    const themeFontSettings = Object.keys(fontSettings) as Array<
      keyof IBoardFontSettings
    >
    themeFontSettings.forEach((setting) => {
      this.fontSettings[setting].setColor(fontSettings[setting].color as string)
    })
  }

  updateTemplate = async (template: string) => {
    if (template === this.template) return
    this.setIsLoading(true)
    const { data } = await DigitalBoards.update(this.id, {
      template,
    })
    this.set(data.digitalBoard)
    this.digitalBoardStore.aiAssistant.reset()
    this.setIsLoading(false)
    this.resize()
  }

  resetTemplate = async () => {
    this.setIsLoading(true)
    const { data } = await DigitalBoards.update(this.id, {
      template: this.template,
      purgeCssOverride: true,
    })
    this.set(data.digitalBoard)
    this.customizer.resetCssEditor()
    this.digitalBoardStore.aiAssistant.reset()
    this.setIsLoading(false)
    this.resize()
  }

  resetItemDetailOrder = async () => {
    this.itemDetailOrder = this.defaultLayoutItemDetailOrder
    this.debounceSave()
  }

  updateCssOverride = async () => {
    this.setIsLoading(true)
    await DigitalBoards.update(this.id, { cssOverride: this.cssOverride })
    this.setIsLoading(false)
  }

  setColumns = async (columns: number) => {
    if (columns === this.columns) return
    if (['block', 'two_column_blocks'].includes(this.template)) {
      this.set({ columns })
      return
    }

    const itemProducerFontStyles = this.fontSettings.itemProducer
    const itemNameFontStyles = this.fontSettings.itemName
    let updates: Partial<IBoard>
    if (columns > 2) {
      updates = {
        showSectionTitles: false,
        showContainerCalories: false,
        showNewStatus: false,
        showIbu: false,
        itemDetailOrder: this.settings.layoutItemDetailOrder.narrow,
        containerLayout: 'below',
      }

      itemProducerFontStyles.copyStyles(this.fontSettings.secondary)
      itemNameFontStyles.setFontWeight('bold')
    } else {
      updates = {
        showSectionTitles: this.defaultOptions.showSectionTitles,
        showContainerCalories: this.defaultOptions.showContainerCalories,
        showNewStatus: this.defaultOptions.showNewStatus,
        showIbu: this.defaultOptions.showIbu,
        itemDetailOrder: this.defaultLayoutItemDetailOrder,
      }

      itemProducerFontStyles.copyStyles(this.fontSettings.primary)
      itemNameFontStyles.setFontWeight('normal')
      itemProducerFontStyles.setFontWeight('bold')
    }

    if (columns === 4) updates.showAbv = false
    else updates.showAbv = true

    updates.columns = columns
    updates.fontSettings = this.fontSettings.attributes

    this.set(updates)
  }

  updateColumns = async (columns: number) => {
    if (columns === this.columns) return

    this.setColumns(columns)
    DigitalBoards.update(this.id, this.attributes)
  }

  updateCheckinsPlacement = async (
    checkinsPlacement: (typeof this)['checkinsPlacement'],
  ) => {
    if (checkinsPlacement === this.checkinsPlacement) return

    this.set({ checkinsPlacement })
    DigitalBoards.update(this.id, this.attributes)
  }

  setLayout = async (layout: IBoard['layout']) => {
    if (layout === this.layout) return

    this.set({
      layout,
      itemDetailOrder: this.settings.layoutItemDetailOrder[layout],
    })

    if (['compressed', 'table'].includes(layout)) this.setColumns(1)
    else if (this.orientation === 'landscape') this.setColumns(2)

    if (layout === 'compressed' || this.template === 'chalk_01') {
      this.set({ showLabelImage: false })
    } else {
      this.set({
        showLabelImage: this.defaultOptions.showLabelImage,
        containerLayout: 'below',
      })
    }

    if (layout === 'compressed') {
      this.set({ containerLayout: 'side_by_side' })
    }

    const primaryFontStyles = this.fontSettings.primary
    primaryFontStyles.applyToNestedFontStyles('fontFamily')
    primaryFontStyles.applyToNestedFontStyles('color')

    const secondaryFontStyles = this.fontSettings.secondary
    secondaryFontStyles.applyToNestedFontStyles('fontFamily')
    secondaryFontStyles.applyToNestedFontStyles('color')
  }

  updateLayout = async (layout: IBoard['layout']) => {
    if (layout === this.layout) return
    this.setLayout(layout)

    DigitalBoards.update(this.id, this.attributes)
  }

  updateContainerLayout = async (
    containerLayout: IBoard['containerLayout'],
  ) => {
    if (containerLayout === this.containerLayout) return

    const itemProducerFontStyles = this.fontSettings.itemProducer
    const itemNameFontStyles = this.fontSettings.itemName

    this.set({ containerLayout })
    if (containerLayout !== 'below' && this.columns > 1) {
      this.set({
        itemDetailOrder: this.settings.layoutItemDetailOrder.narrow,
      })

      this.fontSettings.itemProducer.copyStyles(this.fontSettings.secondary)
      this.fontSettings.itemName.setFontWeight('bold')
    } else {
      this.set({
        itemDetailOrder: this.defaultLayoutItemDetailOrder,
      })
      itemProducerFontStyles.copyStyles(this.fontSettings.primary)
      itemNameFontStyles.setFontWeight('normal')
      itemProducerFontStyles.setFontWeight('bold')
    }

    DigitalBoards.update(this.id, this.attributes)
  }

  setOrientation = async (orientation: 'landscape' | 'portrait') => {
    this.orientation = orientation

    this.setColumns(this.isLandscape && this.layout === 'default' ? 2 : 1)
  }

  updateOrientation = async (orientation: 'landscape' | 'portrait') => {
    if (orientation === this.orientation) return

    this.setOrientation(orientation)

    DigitalBoards.update(this.id, this.attributes)
  }

  resize = () => {
    if (!this.public) {
      this.scale = this.calculateScale()
    }

    this.fontResizer.run()
  }

  calculateScale() {
    const isLandscape = this.orientation === 'landscape'
    const scale = isLandscape
      ? this.containerWidth / this.width
      : this.containerHeight / this.height

    return scale > 1 ? 1 : scale
  }

  previousPage = ({ manual } = { manual: false }) => {
    this.decisecondsElapsed = 0
    if (this.shouldDisplayAds && this.onFirstPage) {
      const nextAdSlide =
        this.adSlideDisplayStyle === 'all_each_loop'
          ? this.ads.adSlidesWithContent.length - 1
          : this.ads.currentSlideIndex
      this.rotateAdSlides(nextAdSlide)
      return
    }
    if (this.multipleDisplays && !manual) return
    if (this.pageCount <= 1) return

    this.setPage(this.onFirstPage ? this.pageCount - 1 : this.page - 1)
  }

  nextPage = ({ manual } = { manual: false }) => {
    this.decisecondsElapsed = 0
    if (
      this.shouldDisplayAds &&
      (this.onLastPage || (this.multipleDisplays && !manual))
    ) {
      const nextAdSlide =
        this.adSlideDisplayStyle === 'all_each_loop'
          ? 0
          : this.ads.currentSlideIndex
      this.rotateAdSlides(nextAdSlide)
      return
    }
    if (this.multipleDisplays && !manual) return
    if (this.pageCount <= 1) return

    this.setPage((this.page + 1) % this.pageCount)
  }

  rotateAdSlides = (slide: number) => {
    if (this.paginationInterval) clearInterval(this.paginationInterval)
    this.ads.setField('currentSlideIndex', slide)
    this.ads.startSlideRotation()
  }

  updateProgress = () => {
    this.decisecondsElapsed += 1
    if (this.decisecondsElapsed >= this.screenRotationSeconds * 10) {
      this.nextPage()
      return
    }
  }

  startPagination = () => {
    if (this.paginationInterval) clearInterval(this.paginationInterval)
    this.paginationInterval = setInterval(() => {
      this.updateProgress()
    }, 100)
  }

  trackPageView = () => {
    const keen = this.digitalBoardStore.rootStore.config.keen
    const client = new KeenTracking(keen)

    // @ts-ignore
    client.initAutoTracking()

    client.recordEvent('pageviews', {
      digital_board: {
        id: this.id,
        location_id: this.locationId,
        location_name: '',
        current_page: this.page,
        ad_slide_display_style: this.adSlideDisplayStyle,
        multiple_displays: this.multipleDisplays,
        show_ad_slides: this.showAdSlides,
        font_family: this.fontFamily,
      },
    })
  }

  setInitialViewportScale = () => {
    const scale = this.calculateScale()
    const viewport = document.querySelector('meta[name=viewport]')

    viewport?.setAttribute(
      'content',
      `width=device-width, initial-scale=${scale}, maximum-scale=${scale}`,
    )
  }

  setupKeyboardNavigation = () => {
    document.addEventListener('keydown', (event) => {
      if (event.code === 'ArrowLeft') {
        if (this.ads.visible) return this.ads.previousAdSlide({ manual: true })
        this.previousPage({ manual: true })
      } else if (event.code === 'ArrowRight') {
        if (this.ads.visible) return this.ads.nextAdSlide({ manual: true })
        this.nextPage({ manual: true })
      }
    })
  }

  // Fire TV will dynamically add a rotation class to the body when the user rotates the screen.
  // We need to listen for this and resize the fonts when it happens.
  listenForFireTVRotation = () => {
    const body = document.querySelector('body')
    if (!body) return
    const mutationObserver = new MutationObserver((mutationList) => {
      mutationList.forEach((mutation) => {
        if (mutation.attributeName !== 'class') return

        this.fontResizer.run()
      })
    })

    mutationObserver.observe(body, { attributes: true })
  }

  startReloadTimeout = () => {
    setTimeout(() => {
      window.location.reload()
    }, 1000 * 60 * 60 * 24 * 7) // 7 days
  }

  uploadBackgroundImage = async (file: File | null) => {
    this.backgroundImageUrl = file ? URL.createObjectURL(file) : null
    this.backgroundImageFileName = file ? file.name : null

    DigitalBoards.uploadBackgroundImage(this.id, file)
  }

  uploadHeaderImage = async (file: File | null) => {
    this.headerImageUrl = file ? URL.createObjectURL(file) : null
    this.headerImageFileName = file ? file.name : null

    DigitalBoards.uploadHeaderImage(this.id, file)
  }

  setField = <K extends keyof typeof this>(
    field: string,
    value: (typeof this)[K],
  ) => {
    this[field as keyof typeof this] = value
  }

  setItemDetailOrder = (index: number, itemDetailOrder: string[]) => {
    const oldLine = this.itemDetailOrder[index]
    const fieldsToUpdateStyling = itemDetailOrder.filter(
      (field) => !oldLine?.includes(field),
    )

    let parentStyles: FontStyles
    if (index === 0) parentStyles = this.fontSettings.primary
    else if (index === 1) parentStyles = this.fontSettings.itemDetails
    else if (index === 2 && this.layout === 'table')
      parentStyles = this.fontSettings.primary
    else parentStyles = this.fontSettings.itemDetails

    fieldsToUpdateStyling.forEach((field) => {
      const fontStyles = this.fontSettings[
        `item${humps.pascalize(field)}` as keyof FontSettings
      ] as FontStyles
      fontStyles.copyStyles(parentStyles)
    })

    this.itemDetailOrder[index] = itemDetailOrder
      .filter((detail) => detail)
      .join(',')
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  setCssOverride = (css: string) => {
    this.cssOverride = css
  }
  debounceSetCssOverride = debounce(this.setCssOverride, 500)

  trackDevice = () => {
    return DigitalBoards.trackDevice(this.id)
  }

  getItemDetailLine = (index: number) => {
    if (!this.itemDetailOrder[index]) return []

    return this.itemDetailOrder[index]
      .split(',')
      .filter((detail) => !this.fixedItemDetails.includes(detail))
  }

  get isLandscape() {
    return this.orientation === 'landscape'
  }

  get height() {
    return this.isLandscape ? DEFAULT_HEIGHT : DEFAULT_WIDTH
  }

  get width() {
    return this.isLandscape ? DEFAULT_WIDTH : DEFAULT_HEIGHT
  }

  get defaultLayoutItemDetailOrder() {
    return [...this.settings.layoutItemDetailOrder[this.layout]]
  }

  get hasUnchangedItemDetailOrder() {
    return this.itemDetailOrder.every(
      (line, index) => line === this.defaultLayoutItemDetailOrder[index],
    )
  }

  get showProducer() {
    return (
      this.showBreweryName &&
      this.showWineProducer &&
      this.showSpiritProducer &&
      this.showRtdProducer
    )
  }

  set showProducer(showProducer: boolean) {
    this.showBreweryName = showProducer
    this.showWineProducer = showProducer
    this.showSpiritProducer = showProducer
    this.showRtdProducer = showProducer
  }

  get showStyle() {
    if (!this.isPremium) return this.showBeerStyle
    return (
      this.showBeerStyle &&
      this.showWineCategory &&
      this.showSpiritCategory &&
      this.showRtdCategory
    )
  }

  set showStyle(showStyle: boolean) {
    this.showBeerStyle = showStyle

    if (!this.isPremium) return
    this.showWineCategory = showStyle
    this.showSpiritCategory = showStyle
    this.showRtdCategory = showStyle
  }

  get showAllAbv() {
    if (!this.isPremium) return this.showAbv
    return (
      this.showAbv && this.showWineAbv && this.showSpiritAbv && this.showRtdAbv
    )
  }

  set showAllAbv(showAllAbv: boolean) {
    this.showAbv = showAllAbv

    if (!this.isPremium) return
    this.showWineAbv = showAllAbv
    this.showSpiritAbv = showAllAbv
    this.showRtdAbv = showAllAbv
  }

  get showVintage() {
    if (!this.isPremium) return false
    return this.showWineVintage && this.showSpiritVintage && this.showRtdVintage
  }

  set showVintage(showVintage: boolean) {
    this.showWineVintage = showVintage
    this.showSpiritVintage = showVintage
    this.showRtdVintage = showVintage
  }

  get showAllCalories() {
    return (
      this.showCalories &&
      this.showWineCalories &&
      this.showSpiritCalories &&
      this.showRtdCalories
    )
  }

  set showAllCalories(showAllCalories: boolean) {
    this.showCalories = showAllCalories
    this.showWineCalories = showAllCalories
    this.showSpiritCalories = showAllCalories
    this.showRtdCalories = showAllCalories
  }

  get showDescription() {
    return (
      this.showBeerDescription &&
      this.showFoodDescription &&
      this.showWineDescription &&
      this.showSpiritDescription &&
      this.showRtdDescription &&
      this.showGenericItemDescription
    )
  }

  set showDescription(showDescription: boolean) {
    this.showBeerDescription = showDescription
    this.showFoodDescription = showDescription
    this.showWineDescription = showDescription
    this.showSpiritDescription = showDescription
    this.showRtdDescription = showDescription
    this.showGenericItemDescription = showDescription
  }

  get showAllLabelImages() {
    return (
      this.showBeerLabelImage &&
      this.showWineLabelImage &&
      this.showSpiritLabelImage &&
      this.showRtdLabelImage &&
      this.showFoodLabelImage &&
      this.showGenericItemLabelImage &&
      this.showLabelImage
    )
  }

  set showAllLabelImages(showLabelImage: boolean) {
    this.showBeerLabelImage = showLabelImage
    this.showWineLabelImage = showLabelImage
    this.showSpiritLabelImage = showLabelImage
    this.showRtdLabelImage = showLabelImage
    this.showFoodLabelImage = showLabelImage
    this.showGenericItemLabelImage = showLabelImage
    this.showLabelImage = showLabelImage
  }

  get showLocation() {
    if (!this.isPremium) return this.showBreweryLocation
    return this.showBreweryLocation && this.showWineRegion
  }

  set showLocation(showLocation: boolean) {
    this.showBreweryLocation = showLocation

    if (!this.isPremium) return
    this.showWineRegion = showLocation
  }

  get showContainers() {
    return (
      this.showContainerName || this.showContainerCalories || this.showPrice
    )
  }

  set showContainers(showContainers: boolean) {
    this.showContainerName = showContainers
    this.showContainerCalories = showContainers
    this.showPrice = showContainers
    this.showCurrencySymbol = showContainers
  }

  get onFirstPage() {
    return this.page === 0
  }

  get onLastPage() {
    return this.page >= this.pageCount - 1
  }

  get shouldDisplayAds() {
    return this.ads.hasAdSlides && this.showAdSlides
  }

  get pages() {
    const pages = []
    let currentPage: Menu[] = []
    let currentItemCount = 0
    let currentMenu: Menu | null = null
    let currentSection: Section | null = null

    const nextPage = () => {
      pages.push(currentPage)
      currentPage = []
      currentMenu = null
      currentSection = null
      currentItemCount = 0
    }

    this.menus.forEach((menu) => {
      const sections =
        menu.onDeckSection && this.showOnDeckItems
          ? [...menu.sections, menu.onDeckSection]
          : menu.sections

      sections
        .filter((section) => section.itemsCount > 0)
        .forEach((section) => {
          section.items.forEach((item) => {
            if (currentItemCount === this.beersOnScreen) nextPage()

            runInAction(() => {
              item.setShowSectionName(false)
            })

            if (currentItemCount === this.beersOnScreen) nextPage()
            if (!currentMenu) {
              currentMenu = { ...menu, sections: [] }
              currentPage.push(currentMenu)
            }

            if (!currentSection) {
              currentSection = {
                ...section,
                items: [],
              }
              currentMenu.sections.push(currentSection)

              runInAction(() => {
                item.setShowSectionName(true)
              })
            }
            currentSection.items.push(item)
            currentItemCount++
          })

          currentSection = null
        })

      if (currentPage.length && this.pageBreakAfterMenu) nextPage()

      currentMenu = null
    })

    // Push the last page if it is not full
    if (currentPage.length > 0) {
      pages.push(currentPage)
    }

    return pages
  }

  convertContainerSizeToFloat = (containerSize: string) => {
    if (containerSize.includes('/')) {
      const [a, b] = containerSize.split('/')
      return parseFloat(a) / parseFloat(b)
    }

    return parseFloat(containerSize)
  }

  get containerSizes() {
    const containerSizes = this.currentPage.flatMap((menu) =>
      menu.sections.flatMap((section) =>
        section.items.flatMap((item) =>
          item.containers
            .filter((container) => container.price)
            .map((container) => container.name || ''),
        ),
      ),
    )

    // sort by numerical value first, then by alphabetical order
    // if the string is a fraction, it converts it to a decimal value to compare. For example, `3 / 5` becomes `0.6`
    containerSizes.sort((a, b) => {
      const aNum = this.convertContainerSizeToFloat(a)
      const bNum = this.convertContainerSizeToFloat(b)

      if (aNum && bNum) return aNum - bNum

      return a.localeCompare(b)
    })

    return new Set(containerSizes)
  }

  get labelImageShape() {
    if (['square', 'circle'].includes(this.labelImageCornerRadius)) {
      return this.labelImageCornerRadius
    }

    return 'custom'
  }

  set labelImageShape(shape: string) {
    if (shape === 'custom') this.labelImageCornerRadius = '0'
    else this.labelImageCornerRadius = shape
  }

  get pageCount() {
    return this.pages.length
  }

  get currentPage() {
    return this.pages[this.page] || []
  }

  get visibleItems() {
    return this.currentPage.flatMap((menu) =>
      menu.sections.flatMap((section) => section.items),
    )
  }

  get rotationProgressPercentage() {
    return (this.decisecondsElapsed / (this.screenRotationSeconds * 10)) * 100
  }

  get customBackgroundImageCss() {
    return this.backgroundImageUrl ? `url(${this.backgroundImageUrl})` : 'none'
  }

  get showSectionSeparator() {
    return ['chalk_01'].includes(this.template)
  }

  get containerWidth() {
    if (this.public) return window.innerWidth

    return window.innerWidth - CUSTOMIZER_WIDTH - PREVIEW_PADDING
  }

  get containerHeight() {
    if (!this.public) return window.innerHeight - PREVIEW_PADDING
    const hasFireTvAppVersion = Boolean(
      localStorage.getItem('fireTvAppVersion'),
    )

    // Fire TV devices have a different screen rotation behavior, which essentially uses the width for the height,
    // and vice versa when rotating the screen for a vertical display.
    if (window.navigator.userAgent.includes('AFT') || hasFireTvAppVersion) {
      return window.innerWidth
    }

    return window.innerHeight
  }

  get isDefault() {
    return this.layout === 'default'
  }

  get isCompressed() {
    return this.layout === 'compressed'
  }

  get isOldTemplate() {
    if (this.isCustom) return true
    return OLD_EXPERIENCE_TEMPLATE_IDS.includes(this.template)
  }

  get isCustom() {
    return this.template.startsWith('custom')
  }

  get isTableLayout() {
    return this.layout === 'table'
  }

  get isDeprecatedTemplate() {
    return DEPRECATED_TEMPLATES.includes(this.template)
  }

  get disabledOptions() {
    const result = this.settings.disabledOptions.map((option) =>
      humps.camelize(option),
    )
    if (result.includes('showLabelImage')) result.push('showAllLabelImages')
    return result
  }

  get fontAutosizing() {
    return ['consistent', 'shrink_to_fit'].includes(this.fontSizing)
  }

  get isPremium() {
    return this.subscriptionTier === 'premium'
  }

  get defaultOptions() {
    return this.settings.defaultOptions
  }

  // Returns item details that cannot be reordered
  get fixedItemDetails() {
    const fixedTapNumber = [
      'two_column_blocks',
      'block',
      'taps_01',
      'taps_02',
      'taps_03',
    ].includes(this.template)

    if (fixedTapNumber) return ['tap_number']

    return []
  }

  get showPrimaryAccentColor() {
    return [
      'two_column_blocks',
      'block',
      'food_01',
      'food_02',
      'taps_01',
      'taps_02',
      'taps_03',
      'wine_01',
      'wine_02',
      'vibrant_01',
      'st_paddys',
      'camo',
      'football',
      'oktoberfest',
    ].includes(this.template)
  }

  get hasBeenUpdated() {
    return Object.keys(this.attributes).some((key) => {
      const field = key as keyof IBoard
      return !isEqual(this.defaults[field], this.attributes[field])
    })
  }

  get hasItems() {
    return (
      this.menus.flatMap((menu) =>
        menu.sections.flatMap((section) => section.items),
      ).length > 0
    )
  }

  get general() {
    return {
      pageBreakAfterMenu: this.pageBreakAfterMenu,
      adSlideDisplayStyle: this.adSlideDisplayStyle,
      checkinsPlacement: this.checkinsPlacement,
    }
  }

  get display() {
    return {
      screenRotationSeconds: this.screenRotationSeconds,
      multipleDisplays: this.multipleDisplays,
    }
  }

  get displayInformation() {
    return {
      showOnDeckItems: this.showOnDeckItems,
      showAbv: this.showAbv,
      showAdSlides: this.showAdSlides,
      showAnnouncements: this.showAnnouncements,
      showBeerStyle: this.showBeerStyle,
      showBreweryLocation: this.showBreweryLocation,
      showBreweryName: this.showBreweryName,
      showCheckins: this.showCheckins,
      showContainers: this.showContainers,
      showContainerName: this.showContainerName,
      showCurrencySymbol: this.showCurrencySymbol,
      showEvents: this.showEvents,
      showIbu: this.showIbu,
      showLabelImage: this.showLabelImage,
      showBeerLabelImage: this.showBeerLabelImage,
      showWineLabelImage: this.showWineLabelImage,
      showSpiritLabelImage: this.showSpiritLabelImage,
      showRtdLabelImage: this.showRtdLabelImage,
      showFoodLabelImage: this.showFoodLabelImage,
      showGenericItemLabelImage: this.showGenericItemLabelImage,
      showMenuTitles: this.showMenuTitles,
      showPrice: this.showPrice,
      showSectionTitles: this.showSectionTitles,
      showTapNumber: this.showTapNumber,
      showUntappdRating: this.showUntappdRating,
      showNewStatus: this.showNewStatus,
      showCalories: this.showCalories,
      showContainerCalories: this.showContainerCalories,
      showGenericItemDescription: this.showGenericItemDescription,
      showSpiritProducer: this.showSpiritProducer,
      showSpiritCategory: this.showSpiritCategory,
      showSpiritAbv: this.showSpiritAbv,
      showSpiritVintage: this.showSpiritVintage,
      showSpiritCalories: this.showSpiritCalories,
      showWineProducer: this.showWineProducer,
      showWineCategory: this.showWineCategory,
      showWineAbv: this.showWineAbv,
      showWineCharacteristics: this.showWineCharacteristics,
      showWineVintage: this.showWineVintage,
      showWineCalories: this.showWineCalories,
      showWineRegion: this.showWineRegion,
      showSpiritDescription: this.showSpiritDescription,
      showWineDescription: this.showWineDescription,
      showRtdProducer: this.showRtdProducer,
      showRtdCategory: this.showRtdCategory,
      showRtdAbv: this.showRtdAbv,
      showRtdVintage: this.showRtdVintage,
      showRtdCalories: this.showRtdCalories,
      showRtdDescription: this.showRtdDescription,
      showFoodDescription: this.showFoodDescription,
      showBeerDescription: this.showBeerDescription,
    }
  }

  get structure() {
    return {
      orientation: this.orientation,
      columns: this.columns,
      layout: this.layout,
      containerLayout: this.containerLayout,
      beersOnScreen: this.beersOnScreen,
      itemDetailOrder: this.itemDetailOrder,
    }
  }

  get appearance() {
    return {
      theme: this.theme,
      fontFamily: this.fontFamily,
      backgroundColor: this.backgroundColor,
      backgroundImageFileName: this.backgroundImageFileName,
      backgroundImageOpacity: this.backgroundImageOpacity,
      menuTitleFontColor: this.menuTitleFontColor,
      sectionTitleFontColor: this.sectionTitleFontColor,
      primaryFontColor: this.primaryFontColor,
      secondaryFontColor: this.secondaryFontColor,
      primaryAccentColor: this.primaryAccentColor,
      menuTitleBackgroundColor: this.menuTitleBackgroundColor,
      sectionTitleBackgroundColor: this.sectionTitleBackgroundColor,
      onDeckTagBackgroundColor: this.onDeckTagBackgroundColor,
      newTagBackgroundColor: this.newTagBackgroundColor,
      untappdRatingColor: this.untappdRatingColor,
      labelImageCornerRadius: this.labelImageCornerRadius,
    }
  }

  get header() {
    return {
      headerImage: this.headerImage,
      headerImageFileName: this.headerImageFileName,
      headerImageSize: this.headerImageSize,
      headerImageAlignment: this.headerImageAlignment,
    }
  }

  get resizeableAttributes() {
    return Object.values({
      ...this.structure,
      ...omit(this.displayInformation, 'showAdSlides'),
      cssOverride: this.cssOverride,
      page: this.page,
      pageBreakAfterMenu: this.pageBreakAfterMenu,
      fontSizing: this.fontSizing,
      headerImageUrl: this.headerImageUrl,
      headerImageSize: this.headerImageSize,
      itemDetailOrder: this.itemDetailOrder.join(','),
      visibleItems: this.visibleItems.map((item) => item.id).join(','),
    })
  }

  get attributes(): Partial<IBoard> {
    return {
      ...this.general,
      ...this.display,
      ...this.structure,
      ...this.displayInformation,
      ...this.appearance,
      ...this.header,
      fontSettings: this.fontSettings.attributes,
      fontSizing: this.fontSizing,
      cssOverride: this.cssOverride,
    }
  }
}
