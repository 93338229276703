import Base from './Base'

class CurrentUser extends Base {
  static retrieve() {
    return this.get('/current_user')
  }

  static async dismissNotice(notice: string) {
    return this.patch('/dismiss_notice', {
      notice,
    })
  }

  static async resendEmailConfirmation() {
    return this.post('/resend_email_confirmation')
  }
}

export default CurrentUser
