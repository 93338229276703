import { makeAutoObservable, runInAction } from 'mobx'
import { CSSObject } from '@mui/material'

import FontSettings from '.'

export type FontStyleGroup = 'primary' | 'secondary' | 'itemDetails' | null

export default class FontStyles implements Partial<CSSObject> {
  [index: string]: unknown
  fontSettings: FontSettings
  color: string
  fontFamily: string
  fontSize: number
  fontWeight: string
  fontStyle: string
  textDecoration: string
  group: FontStyleGroup

  constructor(
    fontSettings: FontSettings,
    attributes: CSSObject,
    group: FontStyleGroup,
  ) {
    Object.assign(this, {
      fontSettings,
      group,
      color: attributes.color || '',
      fontFamily: (String(attributes.fontFamily) || '').replace('"', ''),
      fontSize: attributes.fontSize || 0,
      fontWeight: attributes.fontWeight || '',
      fontStyle: attributes.fontStyle || '',
      textDecoration: attributes.textDecoration || '',
    })

    makeAutoObservable(this, { fontSettings: false })
  }

  set(params: object) {
    Object.assign(this, params)
  }

  setColor(color: string) {
    this.color = color
    this.applyToNestedFontStyles('color')
  }

  setFontFamily(fontFamily: string) {
    this.fontFamily = fontFamily
    this.applyToNestedFontStyles('fontFamily')

    this.fontSettings.board.fontResizer.run()
  }

  setFontSize(fontSize: number) {
    this.fontSize = fontSize
    this.applyToNestedFontStyles('fontSize')

    this.fontSettings.board.fontResizer.run()
  }

  setFontWeight(fontWeight: string) {
    this.fontWeight = fontWeight
    this.applyToNestedFontStyles('fontWeight')
  }

  setFontStyle(fontStyle: string) {
    this.fontStyle = fontStyle
    this.applyToNestedFontStyles('fontStyle')
  }

  setTextDecoration(textDecoration: string) {
    this.textDecoration = textDecoration
    this.applyToNestedFontStyles('textDecoration')
  }

  getNestedFontStyles(field: string) {
    return this.fontSettings[field as keyof FontSettings] as FontStyles
  }

  applyToNestedFontStyles(attribute: keyof FontStyles) {
    this.nestedFontStyles.forEach((field) => {
      const nestedField = this.getNestedFontStyles(field)
      if (!nestedField) return

      runInAction(() => {
        nestedField[attribute] = this[attribute]
      })
    })
  }

  copyStyles(fontStyles: FontStyles) {
    this.setColor(fontStyles.color)
    this.setFontFamily(fontStyles.fontFamily)
    this.setFontSize(fontStyles.fontSize)
    this.setFontWeight(fontStyles.fontWeight)
    this.setFontStyle(fontStyles.fontStyle)
    this.setTextDecoration(fontStyles.textDecoration)
  }

  get nestedFontStyles() {
    if (this.group === 'primary') return this.fontSettings.primaryFields
    if (this.group === 'secondary') return this.fontSettings.secondaryFields
    if (this.group === 'itemDetails') return this.fontSettings.itemDetailFields

    return []
  }

  get attributes() {
    return {
      color: this.color,
      fontFamily: this.fontFamily,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
      fontStyle: this.fontStyle,
      textDecoration: this.textDecoration,
    }
  }

  get displayAttributes() {
    return {
      ...this.attributes,
      fontFamily: `"${this.fontFamily}"`,
    }
  }
}
