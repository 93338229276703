import { makeAutoObservable } from 'mobx'

import Board from './Board'
import { InfoPanel } from '@app/interfaces/boards'

const ROTATION_INTERVAL = 60000

export default class InfoBar {
  board: Board
  currentPanelIndex = 0
  interval: NodeJS.Timeout | null = null

  constructor(board: Board) {
    this.board = board
    makeAutoObservable(this)

    this.startRotation()
  }

  get showCheckins() {
    if (this.board.checkinsPlacement !== 'bottom') return false

    return this.board.checkins.length > 0
  }

  get showEvents() {
    return this.board.events.length > 0 && this.board.showEvents
  }

  get showAnnouncements() {
    return !!this.board.announcement && this.board.showAnnouncements
  }

  get show() {
    return this.showCheckins || this.showEvents || this.showAnnouncements
  }

  get currentPanel() {
    return this.panels[this.currentPanelIndex]
  }

  get panels() {
    const panels: InfoPanel[] = []

    if (this.showCheckins) panels.push('checkins')
    if (this.showEvents) panels.push('events')
    if (this.showAnnouncements) panels.push('announcement')

    return panels
  }

  startRotation = () => {
    if (this.interval) clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.rotate()
    }, ROTATION_INTERVAL)
  }

  rotate = () => {
    if (!this.show) return

    this.currentPanelIndex = (this.currentPanelIndex + 1) % this.panels.length
  }
}
